<template>
  <thead v-if="loaded">
    <tr :class="`${sticky ? 'sticky-header-row':''}`">
      <slot name="before" :dateHeaders="dateHeaders">
        <th
          :class="`${sticky ? 'sticky-header-row':''}`"
          :rowspan="
            dateHeaders && dateHeaders.length > 0 ? dateHeaders.length : 1
          "
          v-for="pre in prefixColumns"
          :key="pre"
        >
          {{pre}}
        </th>
      </slot>
      <th
        :class="`${alignment} date-header`"
        :colspan="dateHeaders.length == 1 ? leafColspan:(openDates.length*leafColspan)"
        v-if="!hideExpanableColumns && openDates && openDates.length > 0"
      >
        <!-- root level -->
        <span class="header_text">
        <v-icon @click="toggleDateNode(dateHeaders[0][0])" small class="mr-2">{{
          dateHeaders[0][0].state == "closed"
            ? "mdi-plus-box-outline"
            : "mdi-minus-box-outline"
        }}</v-icon
        >
        {{ dateHeaders[0][0].label }}
        </span>
      </th>
      <slot name="after" :dateHeaders="dateHeaders">
        <th
          :class="`${sticky ? 'sticky-header-row':''}`"
          :rowspan="
            dateHeaders && dateHeaders.length > 0 ? dateHeaders.length : 1
          "
          v-for="post in postfixColumns"
          :key="post"
        >
          {{post}}
        </th>
      </slot>
    </tr>
    <template v-if="!hideExpanableColumns">
    <tr
      :class="`${sticky ? 'sticky-header-row-' + (rIndex + 2):''}`"
      v-for="(row, rIndex) in dateHeaders.slice(1)"
      :key="rIndex"
    >
      <th
        :colspan="col.isLeaf && col.state != 'open' ? leafColspan:(col.colspan*leafColspan)"
        :rowspan="col.state == 'open' ? 1 : col.maxDepth"
        :class="`${alignment} ${col.children && col.children.length > 0 ? 'date-header':''}`"
        v-for="(col, cIndex) in row"
        :key="rIndex + '-' + cIndex"
        @click="toggleDateNode(col)"
      >
        <span class="header_text">
          <v-icon
            v-if="col.children && col.children.length > 0"
            small
            class="mr-2"
            >{{
              col.state == "closed"
                ? "mdi-plus-box-outline"
                : "mdi-minus-box-outline"
            }}</v-icon
          >
          {{ col.label }}
        </span>
      </th>
    </tr>
    </template>
  </thead>
</template>

<script>
import DateTree from "@/services/DateTree.js";

export default {
  props: {
    value: {
      type: [Array],
      required: false,
    },
    startDate: {
      type: [String, Date],
      required: true,
    },
    endDate: {
      type: [String, Date],
      required: true,
    },
    prefix: {
        type: [Array, String],
        required: false,
        default: null
    },
    postfix: {
        type: [Array, String],
        required: false,
        default: null
    },
    leafColspan: {
        type: Number,
        required: false,
        default: 1
    },
    alignment: {
        type: String,
        required: false,
        default: 'text-left'
    },
    sticky: {
      type: Boolean,
      required: false,
      default: false
    },
    hideExpanableColumns: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
        dateTree: null,
        dateHeaders: [],
        openDates: [],
        loaded: false,
        prefixColumns: [],
        postfixColumns: [],
        headerAlignment: null
    };
  },
  created() {
    this.dateTree = new DateTree(this.startDate, this.endDate);
    this.dateHeaders = this.dateTree.getOpenLevels();
    this.openDates = this.dateTree.getOpenLeafs();
    this.$emit('input', this.openDates);

    if(this.prefix && Array.isArray(this.prefix)){
        this.prefixColumns = this.prefix;
    }
    else if(this.prefix){ 
        this.prefixColumns = [this.prefix];
    }

    if(this.postfix && Array.isArray(this.postfix)){
        this.postfixColumns = this.postfix;
    }
    else if(this.postfix){ 
        this.postfixColumns = [this.postfix];
    }

    this.loaded = true;
  },
  methods: {
      toggleDateNode(node){
        this.dateTree.toggleState(node.id);
        this.dateHeaders = this.dateTree.getOpenLevels();
        this.openDates = this.dateTree.getOpenLeafs();

        this.$emit('input', this.openDates);
      }
  }
};
</script>

<style scoped>

span.header_text{
  position: sticky;
  left: 45%;
}

thead th:hover.date-header{
  background-color: #FAFAFA;
  cursor: pointer;
}

thead th {
  background-color: white;
  white-space: nowrap;
}

.sticky-header-row th:first-child {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 0;
  z-index: 4;
}

.sticky-header-row th:not(:first-child) {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}

.sticky-header-row-2 th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 48px;
  z-index: 2;
}

.sticky-header-row-3 th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 96px;
  z-index: 2;
}

.sticky-header-row-4 th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 144px;
  z-index: 2;
}

.sticky-header-row-5 th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  top: 144px;
  z-index: 2;
}
</style>
