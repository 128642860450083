import TreeModel from "tree-model";
import moment from 'moment';

Date.prototype.addHours = function(hours) {
  var date = new Date(this.valueOf());
  date.setHours(date.getHours() + hours);
  return date;
}

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.addMonths = function(months) {
  var date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
}

Date.prototype.addYears = function(years) {
  var date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export default class DateTree {

  constructor(startDate, endDate) {
    this.startDate = startDate;
    this.endDate = endDate;
    const momentStart = moment(startDate);
    const momentEnd = moment(endDate);
    // this.levels = ['year', 'month', 'day', 'hour'];
    if(momentStart.format('MMM, DD YYYY') == momentEnd.format('MMM, DD YYYY')) {
      this.levels = ['hour'];
    }
    else{
      const numberOfMonths = momentEnd.diff(momentStart, 'months')
      if(numberOfMonths < 1){
        this.levels = ['day'];
      }
      else{
        this.levels = ['month', 'day'];
      }
    }

    const root = this._buildRoot(startDate, endDate)
    this._buildModel(root);
    const dateTree = new TreeModel();
    this.tree = dateTree.parse(root)

  }

  getRootNode(){
    return this.tree.first((node) => node.model.level == 'root' );
  }

  toggleState(nodeId){
    const node = this.tree.first((node) => node.model.id == nodeId );
    if(node.children && node.children.length == 1 && node.children[0].model.state == node.model.state){
      this.toggleState(node.children[0].model.id);
    }
    
    node.model.state = node.model.state == 'closed' ? 'open' : 'closed';
    
    //set all descendent to be hidden
    if(node.model.state == 'closed'){
      const allDescendents = node.all( () => true)
      allDescendents.forEach(n => {
        n.model.visible = false;
        n.model.state = 'closed';
      })
    }

    return node;
  }

  getOpenLevels(){
    let openNodes = this.tree.all({strategy: 'breadth'}, function (node) {
        return node.model.level == 'root' || node.model.state != 'closed';
    });
    // add open nodes' children
    openNodes.forEach(n => {
      n.model.visible = true;
      if(n.model.state == 'open' && n.children && n.children.length > 0){
        n.children.forEach(c => c.model.visible = true)
        openNodes = openNodes.concat(n.children)
      }
    })

    //remove dulplicates from openNodes
    openNodes = openNodes.filter((node, index, self) =>
      index === self.findIndex((t) => ( t.model.id === node.model.id))
    )

    //assign colspan to each node
    openNodes.forEach(node => {
      const colspan = this.getColSpan(node);
      node.model.colspan = colspan;

      //lowest level
      if(colspan == 1){
        node.model.isLeaf = true;
      }
    })

    const results = openNodes.reduce((groupedLevels, node) => { 
      const level = node.getPath().length -1;
      if(groupedLevels[level]){
        groupedLevels[level].push(node)
      }
      else{
        groupedLevels[level] = [node]
      }
      return groupedLevels;
    }, []);

    results.forEach( group => {
      let groupMaxDepth = 0;
      group.forEach( levelNode => {
        const depth = this.getVisibleDepth(levelNode);
        if(depth > groupMaxDepth){
          groupMaxDepth = depth;
        }
      })

      group.forEach( n => n.model.maxDepth = groupMaxDepth)
      group = group.sort( (a, b) => a.model.startTime - b.model.startTime)
    })
    //remove all levels that only have 1 node, other than the root
    // const removeLevels = [];
    // results.forEach( (row, index) => {
    //   if(index > 0 && row.length <= 1){
    //     removeLevels.push(index)
    //   }
    // })

    // for (var i = removeLevels.length -1; i >= 0; i--){
    //   results.splice(removeLevels[i],1);
    // }

    return results.map( r => r.map(c => c.model))
  }

  /**
   * Calculate the max depth of the node that's visible
   * @param {*} node 
   * @returns 
   */
  getVisibleDepth(node){
    let depth = 0;

    if(node.model.visible && (!node.children || node.children.length == 0)){
      depth = 1;
    }
    else if(node.model.visible){
      // if(node.model.id == '0-0'){
      //   debugger;
      // }

      const visibleChildrens = node.all((n) => {
        return n.model.visible
      })

      visibleChildrens.forEach( childNode => {
        let path = childNode.getPath();
        path = path.splice(path.findIndex( p => p.model.id == node.model.id), path.length);
        if(depth < path.length){
          depth = path.length;
        }
      })
    }

    node.model.depth = depth;
    return depth;
  }

  getColSpan(node){
    let colspan = 0;

    if(!node.children || node.children.length == 0){
      if(node.model.visible){
        return 1
      }
      else 0
    }
    else if(node.children && node.children){
      let visibleChildrenCount = 0;
      node.children.forEach(childNode => {
        if(childNode.model.visible){
          visibleChildrenCount++;
          colspan += this.getColSpan(childNode);
        }
      })

      //if no visible children found, then count self as 1
      if(visibleChildrenCount == 0){
        colspan += 1;
      }

      return colspan;
    }

  }

  getOpenLeafs(){
    // const openLevels = this.getOpenLevels();
    // //Exclude root level
    // if(openLevels && openLevels.length > 0){
    //   return openLevels[openLevels.length -1];
    // }
    // else{
    //   return []
    // }
    const visibleLeafs = this.tree.all( n => {
      if(n.model.visible){
        if(!n.children || n.children.length == 0){
          return true;
        }
        else{
          //node is visible and has non-visible children
          const visibleChildren = n.children.filter(cn => cn.model.visible);
          if(visibleChildren.length == 0){
            return true;
          }
        }
      }
      return false;
    })

    return visibleLeafs.map(n => n.model)
  }


  _buildRoot(startDate, endDate){
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    const startLabel = startMoment.format('MMM, DD YYYY');
    const endLabel = endMoment.format('MMM, DD YYYY');
    const label = startLabel == endLabel ? startLabel:`${startLabel} - ${endLabel}`;
    var root = { 
      id: 0,
      label: label, 
      level: 'root',
      state: 'closed', 
      children: [], 
      startTime: new Date(startMoment.toDate().setHours(0,0,0,0)), 
      endTime: new Date(endMoment.toDate().setHours(23,59,59,999)), 
    };

    return root;
  }

  _buildModel(node) {
    //end recursion when level reach last level.
    const leafLevel = this.levels[this.levels.length-1];
    if(node.level === leafLevel){
      return null;
    }
    else{
      //find next level to build
      const leveIndex = this.levels.findIndex(level => level == node.level);
      const nextLevel = this.levels[leveIndex+1];

      //find all time between the next level
      var currentTime = node.startTime;
      let index = 0;
      while (currentTime <= node.endTime) {
        const startTime = currentTime;
        let endTime = null;
        let label = null;
        if(nextLevel == 'year'){
          label = moment(currentTime).format('YYYY');
          const endOfYear = new Date(new Date(currentTime.getFullYear(), 11, 31).setHours(23,59,59,999));
          // const endOfYear = moment().endOf("year").toDate();
          endTime = endOfYear > node.endTime ? node.endTime:endOfYear;
          currentTime = currentTime.addYears(1);
        }
        else if(nextLevel == 'month'){
          label = moment(currentTime).format('MMM, YYYY');
          const endOfMonth = new Date(new Date(currentTime.getFullYear(), currentTime.getMonth()+1, 0).setHours(23,59,59,999));
          endTime = endOfMonth > node.endTime ? node.endTime:endOfMonth;
          currentTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1).addMonths(1);
          // console.log('processing node: ', currentTime)    
        }
        else if(nextLevel == 'day'){
          label = moment(currentTime).format('MMM, DD');
          const endOfDay = new Date(new Date (currentTime).setHours(23,59,59,999));
          endTime = endOfDay > node.endTime ? node.endTime:endOfDay;
          currentTime = currentTime.addDays(1);
        }
        else if(nextLevel == 'hour'){
          label = moment(currentTime).format('h a');
          const endOfHour = new Date(new Date (currentTime).setHours(currentTime.getHours(),59,59,999));
          endTime = endOfHour > node.endTime ? node.endTime:endOfHour;
          currentTime = currentTime.addHours(1);
        }

        const childNode = {
          id: node.id + '-' +index,
          label,
          level: nextLevel,
          state: 'closed', 
          startTime,
          endTime,
          children: nextLevel == 'hour' ? null:[]
          };
        
        node.children.push(childNode);
        index++;
      }

      if(node.children){
        node.children.forEach((childNode => this._buildModel(childNode)))
      }
    }
  }

}
